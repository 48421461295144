import moment from 'moment';
import i18n from '@/i18n';

export default class Periods {
    static generatePeriods() {
        let startDate = moment().subtract(2, 'years');
        let endDate = moment().endOf('month');
        let months = {};
        let month = startDate;

        while (month <= endDate) {
            if (!(month.format('YYYY') in months)) {
                months[month.format('YYYY')] = [];
            }
            months[month.format('YYYY')].push({
                value: month.format('MM/YYYY'),
                display: i18n.t('shared.general.months.shortForm.' + month.format('MM')) + ' ' + month.format('YYYY')
            });
            month = month.clone().add(1, 'months');
        }
        return months;
    }
    static generateMonthAndYear(year = 1) {
        let startDate = moment().subtract(year, 'years');
        let endDate = moment().endOf('month');
        let months = [];
        let month = startDate;

        while (month <= endDate) {
            months.push({
                value: month.format('MM/YYYY'),
                text: i18n.t('shared.general.months.shortForm.' + month.format('MM')) + ' ' + month.format('YYYY')
            });
            month = month.clone().add(1, 'months');
        }
        return months;
    }
}
