<template>
    <b-card
        :border-variant="borderVariant"
        :bg-variant="backgroundVariant"
        :header="header"
        :header-bg-variant="headerBgVariant"
        :header-text-variant="headerTextVariant"
        :text-variant="textVariant"
        align="center"
        no-body
    >
        <b-card-body>
            <b-card-text>
                <h4>{{ cardText }}</h4>
                <small v-if="additionCardText">{{ additionCardText }}</small>
            </b-card-text>
        </b-card-body>
    </b-card>
</template>

<script>
export default {
    name: 'Card',
    props: {
        borderVariant: {
            type: String,
            required: false,
            default: 'primary'
        },
        header: {
            type: String,
            required: true
        },
        headerBgVariant: {
            type: String,
            required: false,
            default: 'primary'
        },
        headerTextVariant: {
            type: String,
            required: false,
            default: 'white'
        },
        cardText: {
            type: [String, Number],
            required: true
        },
        additionCardText: {
            type: String,
            required: false
        },
        backgroundVariant: {
            type: String,
            required: false,
            default: 'primary'
        },
        textVariant: {
            type: String,
            required: false,
            default: 'white'
        }
    }
};
</script>
