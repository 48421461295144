import { FileRepository } from '@/repositories';

export default {
    downloadFile(api, filename) {
        return new Promise((resolve, reject) => {
            FileRepository.downloadFile(api)
                .then(response => {
                    const url = URL.createObjectURL(
                        new Blob([response.data], {
                            type: response.headers['content-type']
                        })
                    );
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', filename);
                    document.body.appendChild(link);
                    resolve(link);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    downloadExcel(api, filename, global_filters) {
        return new Promise((resolve, reject) => {
            FileRepository.downloadExcel(api, global_filters)
                .then(response => {
                    const url = URL.createObjectURL(
                        new Blob([response.data], {
                            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                        })
                    );
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', filename);
                    document.body.appendChild(link);
                    resolve(link);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }
};
