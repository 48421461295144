<template>
    <div>
        <b-row class="my-3">
            <b-col cols="12" lg="12">
                <b-card class="">
                    <b-form inline>
                        <b-form-radio-group
                            v-model="radioButtons.selected"
                            :options="radioButtons.options"
                            name="radio-inline"
                            @change="changeRadioValue"
                        ></b-form-radio-group>
                        <select-option-group
                            :data="selectBox.startDate"
                            :disabled="radioButtons.selected !== radioButtonsKeys.DYNAMIC_PERIOD"
                            @selectOptionChange="changeSelectOption($event, 'startDate')"
                            id="startDate"
                        ></select-option-group>
                        <select-option-group
                            class="ml-3"
                            :data="selectBox.endDate"
                            :disabled="radioButtons.selected !== radioButtonsKeys.DYNAMIC_PERIOD"
                            @selectOptionChange="changeSelectOption($event, 'endDate')"
                            id="endDate"
                        ></select-option-group>
                    </b-form>
                </b-card>
            </b-col>
        </b-row>

        <b-row class="">
            <b-col cols="12" lg="12">
                <b-card class="mb-3">
                    <b-row>
                        <b-col cols="8" lg="8">
                            {{
                                $t('client.components.periodFilter.info', {
                                    startDate: this.$store.getters['periodFilter/getStartDate'],
                                    endDate: this.$store.getters['periodFilter/getEndDate']
                                })
                            }}
                        </b-col>

                        <b-col cols="4" lg="4">
                            <div v-if="displayDownloadExcelLink()" class="text-right">
                                <b-button size="sm" @click.prevent="downloadExcel()">
                                    <b-icon icon="table" aria-hidden="true"></b-icon>
                                    {{ $t('client.pages.dashboard.buttons.exportExcel') }}
                                </b-button>
                            </div>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import moment from 'moment';
import { COST, RADIO_BUTTONS_KEYS, INTERVENTION_CLIENT, DASHBOARD_CLIENT } from '@/constants';
import { RADIO_BUTTON_YEAR_CHANGE, SELECT_BOX_PERIOD_CHANGE } from '@/store/actions/periodFilter';
import SelectOptionGroup from '@/components/shared/element/SelectOptionGroup';
import Periods from '@/utils/periods';
import FileService from '@/services/FileService';

export default {
    name: 'ClientPeriodFilters',
    components: {
        SelectOptionGroup
    },
    data() {
        return {
            radioButtons: {
                options: COST.RADIO_BUTTONS.OPTIONS,
                selected: this.$store.getters['periodFilter/getSelectedRadioButton']
            },
            selectBox: {
                startDate: {
                    options: Periods.generatePeriods(),
                    selected: this.$store.getters['periodFilter/getSelectBoxStartDate']
                },
                endDate: {
                    options: Periods.generatePeriods(),
                    selected: this.$store.getters['periodFilter/getSelectBoxEndDate']
                }
            },
            radioButtonsKeys: RADIO_BUTTONS_KEYS
        };
    },
    methods: {
        changeRadioValue(checked) {
            this.$store.dispatch(`periodFilter/${RADIO_BUTTON_YEAR_CHANGE}`, checked);
        },
        changeSelectOption($event, id) {
            this.$store.dispatch(`periodFilter/${SELECT_BOX_PERIOD_CHANGE}`, { $event, id });
        },
        displayDownloadExcelLink() {
            return ['ClientDistributionByPercentage', 'ClientDistributionByValue'].includes(this.$route.name);
        },
        downloadExcel() {
            if (! (this.getExcelDownloadUrl() || this.getExcelFilename())) {
                return false;
            }
            const filters = {
                global_filters: this.$store.getters['context/getGlobalFiltersIds'],
                ...this.$store.getters['periodFilter/getStartAndEndDateFilters']
            };

            filters.status = INTERVENTION_CLIENT.RADIO_BUTTONS_KEYS.ALL;

            FileService.downloadExcel(this.getExcelDownloadUrl(), this.getExcelFilename(), filters)
                .then(link => {
                    link.click();
                })
                .catch(() => {
                    this.$toast.error(this.$i18n.t('shared.components.customTable.notifications.errors.pdfNotFound'));
                });
        },
        getExcelDownloadUrl() {
            switch (this.$route.name) {
                case 'ClientDistributionByPercentage':
                    return DASHBOARD_CLIENT.COSTS_COUNTS_INTERVENTIONS.EXPORT.EXCEL_URL
                        .replace('{type}', 'costs');
                case 'ClientDistributionByValue':
                    return DASHBOARD_CLIENT.COSTS_COUNTS_INTERVENTIONS.EXPORT.EXCEL_URL
                        .replace('{type}', 'counts');
                default:
                    return false;
            }
        },
        getExcelFilename() {
            switch (this.$route.name) {
                case 'ClientDistributionByPercentage':
                    return DASHBOARD_CLIENT.COSTS_COUNTS_INTERVENTIONS.EXPORT.EXCEL_FILENAME
                        .replace('{page}', 'costs')
                        .replace('{date}', moment().format('YYYYMMDD'));
                case 'ClientDistributionByValue':
                    return DASHBOARD_CLIENT.COSTS_COUNTS_INTERVENTIONS.EXPORT.EXCEL_FILENAME
                        .replace('{page}', 'counts')
                        .replace('{date}', moment().format('YYYYMMDD'));
                default:
                    return false;
            }
        }
    },
    created() {}
};
</script>
