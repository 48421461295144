<template>
    <div></div>
</template>

<script>
import { INPUT_HEADER_CHANGED, RESET_FILTER } from '@/store/actions/context';
import { RADIO_BUTTON_YEAR_CHANGE, SELECT_BOX_PERIOD_CHANGE } from '@/store/actions/periodFilter';
import { EVENTS } from '@/constants/client/events';

export default {
    name: 'InputFilterEvents',
    created() {
        this.storeSubscriber = this.$store.subscribe(mutation => {
            if (
                mutation.type === `context/${INPUT_HEADER_CHANGED}` ||
                mutation.type === `periodFilter/${RADIO_BUTTON_YEAR_CHANGE}` ||
                mutation.type === `periodFilter/${SELECT_BOX_PERIOD_CHANGE}` ||
                mutation.type === `context/${RESET_FILTER}`
            ) {
                this.$emit(EVENTS.DASHBOARD.INPUT_CLIENTS_AND_PERIOD_FILTER_CHANGE);
            }

            if (mutation.type === `context/${INPUT_HEADER_CHANGED}` || mutation.type === `context/${RESET_FILTER}`) {
                this.$emit(EVENTS.DASHBOARD.INPUT_CLIENTS_FILTER_CHANGE);
            }
        });
    },
    methods: {
        getFilters() {
            return {
                global_filters: this.$store.getters['context/getGlobalFiltersIds'],
                ...this.$store.getters['periodFilter/getStartAndEndDateFilters']
            };
        },
        getClientsAndSiteFilter() {
            return this.$store.getters['context/getGlobalFiltersIds'];
        },
        getPeriodFilter() {
            return {
                period: this.$store.getters['periodFilter/getStartAndEndDateFilters']
            };
        }
    },
    beforeDestroy() {
        this.storeSubscriber();
    }
};
</script>
