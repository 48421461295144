<template>
    <div class="section">
        <client-period-filters></client-period-filters>

        <b-row>
            <b-col lg="8" offset-lg="2">
                <h5>{{ $t('client.pages.performanceIndicators.section1.card.title') }}</h5>
                <hr class="mt-0" />

                <spinner v-if="refreshData"></spinner>

                <div v-if="!refreshData">
                    <b-card-group deck v-for="(items, label) in dataSection1" :key="label">
                        <card
                            :borderVariant="item.displayAlert ? 'danger' : 'secondary'"
                            :backgroundVariant="item.displayAlert ? 'danger' : 'white'"
                            :header="item.label"
                            :headerBgVariant="item.displayAlert ? 'danger' : 'secondary'"
                            :cardText="item.value"
                            :textVariant="item.displayAlert ? 'white' : 'black'"
                            v-for="(item, label) in items.elements"
                            :key="label"
                            :additionCardText="item.additionCardText"
                        />
                        <div v-if="items.title" class="ml-2">
                            <p class="lead text-muted">{{ items.title }}</p>
                        </div>
                    </b-card-group>
                </div>

                <div class="py-3"></div>

                <h5>{{ $t('client.pages.performanceIndicators.section2.card.title') }}</h5>
                <hr class="mt-0" />

                <spinner v-if="refreshData"></spinner>

                <div v-if="!refreshData">
                    <b-card-group deck v-for="(items, label) in dataSection2" :key="label">
                        <card
                            :borderVariant="item.displayAlert ? 'danger' : 'secondary'"
                            :backgroundVariant="item.displayAlert ? 'danger' : 'white'"
                            :header="item.label"
                            :headerBgVariant="item.displayAlert ? 'danger' : 'secondary'"
                            :cardText="item.value"
                            :textVariant="item.displayAlert ? 'white' : 'black'"
                            v-for="(item, label) in items.elements"
                            :key="label"
                            :additionCardText="item.additionCardText"
                        ></card>
                        <div v-if="items.title" class="ml-2">
                            <p class="lead text-muted">{{ items.title }}</p>
                        </div>
                    </b-card-group>
                </div>
            </b-col>
        </b-row>

    </div>
</template>

<script>
import ClientPeriodFilters from '@/components/client/dashboard/ClientPeriodFilters';
import InputFilterEvents from '@/views/client/InputFilterEvents';
import Card from '@/components/shared/card/Card';
import { EVENTS } from '@/constants/client/events';
import { DashboardRepository } from '@/repositories';
import Spinner from '@/components/shared/element/Spinner';

export default {
    name: 'ClientDashboardPerformanceIndicators',
    extends: InputFilterEvents,
    components: {
        ClientPeriodFilters,
        Card,
        Spinner
    },
    data: () => ({
        refreshData: true,
        dataSection1: [],
        dataSection2: []
    }),
    created() {
        this.getData();
        this.$on(EVENTS.DASHBOARD.INPUT_CLIENTS_AND_PERIOD_FILTER_CHANGE, () => {
            this.getData();
        });
    },
    methods: {
        getData() {
            this.refreshData = true;
            this.dataSection1 = [];
            this.dataSection2 = [];
            DashboardRepository.getPerformanceIndicators(this.getFilters())
                .then(performanceResponse => {
                    DashboardRepository.getClientPerformanceIndicators(this.getFilters()).then(clientPerformanceResponse => {
                        this.refreshData = false;
                        const sec1Line1 = {
                            title: this.$t('client.pages.performanceIndicators.section1.cardGroup.titles.state'),
                            elements: []
                        };
                        const sec1Line2 = {
                            title: '',
                            elements: [
                                {
                                    label: this.$t('client.pages.performanceIndicators.section1.card.labels.siteCount'),
                                    value: performanceResponse.data.data.site_count
                                },
                                {
                                    label: this.$t('client.pages.performanceIndicators.section1.card.labels.billedIrCount'),
                                    value: performanceResponse.data.data.billed_ir_count
                                },
                                {
                                    label: this.$t('client.pages.performanceIndicators.section1.card.labels.canceledIrCount'),
                                    value: performanceResponse.data.data.canceled_ir_count
                                },
                                {
                                    label: this.$t('client.pages.performanceIndicators.section1.card.labels.registeredIrCount'),
                                    value: performanceResponse.data.data.registered_ir_count
                                }
                            ]
                        };
                        const sec1Line3 = {
                            title: this.$i18n.t('client.pages.performanceIndicators.section1.cardGroup.titles.financier'),
                            elements: []
                        };
                        const sec1Line4 = {
                            title: '',
                            elements: [
                                {
                                    label: this.$t('client.pages.performanceIndicators.section1.card.labels.caAmount'),
                                    value: this.$n(performanceResponse.data.data.ca_amount, 'currency') + ' HT',
                                    additionCardText: this.$t(
                                        'client.pages.performanceIndicators.section1.card.labels.caAmountPercentage',
                                        {
                                            caAmountPercentage: performanceResponse.data.data.ca_amount_percentage
                                        }
                                    )
                                }
                            ]
                        };
                        const sec1Line5 = {
                            title: '',
                            elements: [
                                {
                                    label: this.$t('client.pages.performanceIndicators.section1.card.labels.avgAmountPerIr'),
                                    value: this.$n(performanceResponse.data.data.avg_amount_per_ir, 'currency') + ' HT'
                                },
                                {
                                    label: this.$t('client.pages.performanceIndicators.section1.card.labels.avgAmountPerSite'),
                                    value: this.$n(performanceResponse.data.data.avg_amount_per_site, 'currency') + ' HT'
                                },
                                {
                                    label: this.$t('client.pages.performanceIndicators.section1.card.labels.currentDebtAmount'),
                                    value: this.$n(performanceResponse.data.data.current_debt.amount, 'currency') + ' TTC'
                                },
                                {
                                    label: this.$t('client.pages.performanceIndicators.section1.card.labels.currentDebtMaxDelay'),
                                    value: performanceResponse.data.data.current_debt.max_delay + ' ' + this.$t('shared.general.days'),
                                    displayAlert: performanceResponse.data.data.current_debt.status === 'alert'
                                }
                            ]
                        };
                        this.dataSection1.push(sec1Line1, sec1Line2, sec1Line3, sec1Line4, sec1Line5);

                        const sec2Line1 = {
                            title: this.$i18n.t('client.pages.performanceIndicators.section2.cardGroup.titles.quote'),
                            elements: []
                        };

                        const sec2Line2 = {
                            title: '',
                            elements: [
                                {
                                    label: this.$t('client.pages.performanceIndicators.section2.card.labels.proposedQuotes'),
                                    value: clientPerformanceResponse.data.data.quotations
                                },
                                {
                                    label: this.$t('client.pages.performanceIndicators.section2.card.labels.quotesAccepted'),
                                    value: clientPerformanceResponse.data.data.approved_quotations
                                },
                                {
                                    label: this.$t('client.pages.performanceIndicators.section2.card.labels.quotesRefused'),
                                    value: clientPerformanceResponse.data.data.denied_quotations
                                },
                                {
                                    label: this.$t('client.pages.performanceIndicators.section2.card.labels.quoteWhithoutFollowUp'),
                                    value: clientPerformanceResponse.data.data.unanswered_quotations
                                }
                            ]
                        };

                        const sec2Line3 = {
                            title: '',
                            elements: [
                                {
                                    label: this.$t('client.pages.performanceIndicators.section2.card.labels.proposalRate'),
                                    value: clientPerformanceResponse.data.data.quotation_rate + '%'
                                },
                                {
                                    label: this.$t('client.pages.performanceIndicators.section2.card.labels.acceptanceRate'),
                                    value: clientPerformanceResponse.data.data.quotation_approval_rate + '%'
                                },
                                {
                                    label: this.$t('client.pages.performanceIndicators.section2.card.labels.refusalRate'),
                                    value: clientPerformanceResponse.data.data.quotation_denial_rate + '%'
                                },
                                {
                                    label: this.$t('client.pages.performanceIndicators.section2.card.labels.rateWhitoutContinuation'),
                                    value: clientPerformanceResponse.data.data.quotation_unanswered_rate + '%'
                                }
                            ]
                        };

                        const sec2Line4 = {
                            title: this.$t('client.pages.performanceIndicators.section2.cardGroup.titles.reactivity'),
                            elements: []
                        };

                        const sec2Line5 = {
                            title: '',
                            elements: [
                                {
                                    label: this.$t('client.pages.performanceIndicators.section2.card.labels.reactivity'),
                                    value: clientPerformanceResponse.data.data.reactivity_delay + ' ' + this.$t('shared.general.days')
                                },
                                {
                                    label: this.$t('client.pages.performanceIndicators.section2.card.labels.quoteFeedbackDeadline'),
                                    value: clientPerformanceResponse.data.data.quotation_delay + ' ' + this.$t('shared.general.days')
                                },
                                {
                                    label: this.$t('client.pages.performanceIndicators.section2.card.labels.leadTime'),
                                    value: clientPerformanceResponse.data.data.operational_delay + ' ' + this.$t('shared.general.days')
                                }
                            ]
                        };

                        const sec2Line6 = {
                            title: this.$t('client.pages.performanceIndicators.section2.cardGroup.titles.quality'),
                            elements: []
                        };

                        const sec2Line7 = {
                            title: '',
                            elements: [
                                {
                                    label: this.$t('client.pages.performanceIndicators.section2.card.labels.satisfaction'),
                                    value: clientPerformanceResponse.data.data.satisfaction + ' %'
                                }
                            ]
                        };

                        this.dataSection2.push(sec2Line1, sec2Line2, sec2Line3, sec2Line4, sec2Line5, sec2Line6, sec2Line7);
                    });
                })
                .catch(error => {
                    this.refreshData = false;
                    console.log(error);
                });
        }
    }
};
</script>
<style lang="scss" scoped>
.lead {
    font-size: 16px;
}

::v-deep {
    .card-header {
        padding: 8px 8px;
    }

    .card-text h4 {
        margin-bottom: 0;
    }
}

@media screen and (min-width: 576px) {
    .card-deck .card {
        flex: 1 0 calc(50% - 16px);
        margin-bottom: 16px;
    }
}
</style>
