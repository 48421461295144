<template>
    <b-form-select
        v-model="data.selected"
        value-field="item"
        text-field="name"
        class="w-25"
        @change="changeSelectOption"
        :disabled="disabled"
    >
        <b-form-select-option-group :label="label" v-for="(items, label) in data.options" :key="label">
            <b-form-select-option :value="item.value" v-for="item in items" :key="item.value">
                {{ item.display }}
            </b-form-select-option>
        </b-form-select-option-group>
    </b-form-select>
</template>

<script>
export default {
    name: 'SelectOptionGroup',
    props: {
        data: {
            type: Object,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        changeSelectOption(value) {
            this.$emit('selectOptionChange', value);
        }
    }
};
</script>
